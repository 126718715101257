<template>
    <b-modal v-model="show" :title="title" size="xl" @close="close" no-close-on-backdrop no-enforce-focus hide-footer>
        <div class="c-arrow-nav">
            <ul class="nav nav-pills nav-fill">
                <li class="nav-item">
                    <button @click="step='edu-event'" class="nav-link" :class="step==='edu-event'?'active':null">
                        <span class="c-arrow-nav__icon"><i class="fa fa-eye"></i></span>
                        <span class="c-arrow-nav__text">
                            Thông tin hoạt động, sự kiện
                        </span>
                    </button>
                </li>
                <li class="nav-item">
                    <button @click="step='student'" :disabled="!edu" class="nav-link" :class="step==='student'?'active':null">
                        <span class="c-arrow-nav__icon"><i class="fa fa-list-alt"></i></span>
                        <span class="c-arrow-nav__text">
                            Người khuyết tật
                        </span>
                    </button>
                </li>
            </ul>
        </div>
        <component :is="`${step}Setting`" :edu="edu" :params="params" @close="close" @next="next" />
    </b-modal>
</template>

<script>
    import EduEvent from "./EduEventForm";
    import Student from "./EduStudentEvent";
    export default {
        props: ['params'],
        components: {
            eduEventSetting: EduEvent,
            studentSetting: Student,
        },
        data() {
            return {
                show: false,
                edu: null,
                step: 'edu-event',
                title: null
            };
        },
        methods: {
            open(edu, step) {
                this.edu = edu;
                if (this.edu) {
                    this.step = step;
                    this.title = `Hoạt động, sự kiện: ${this.edu.name}`;
                } else {
                    this.step = 'edu-event';
                    this.title = "Thông tin hoạt động, sự kiện";
                }
                this.show = true;
            },
            next(step, edu) {
                if (edu) {
                    this.edu = edu;
                }
                this.title = `Hoạt động, sự kiện: ${this.edu.name}`;
                this.step = step;
            },
            close() {
                this.$bus.$emit('reloadEdu');
                this.show = false;
            }
        }
    }
</script>
